@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;

}




/* Theme Css Start */


.bgcolor {
  background-color: #911212;
}

.bggray {
  background-color: #F2F1EB;
}

/* .bgcolor {
  background-color: #EA7A04;
} */

.bgblue {
  background: #911212;
}

.bgyellow {
  background: #fffe00;
}

.bgdarkbrown {
  background: #78350f;
}

.bgwhite {
  background-color: #FFFFFF;
}

.bggreen {
  background-color: green;
}
.bgcolortb{
  background-color: #4b5563;
  color: #FFFFFF;
}

.textcolor {
  color: #911212;
}

.texthover:hover {
  background: #FFFFFF;
  color: #911212;
}

.textred {
  color: #FE0000
}

.textwhite {
  color: #FFFFFF;
}

.textblack {
  color: #000000;
}

.textyellow {
  color: #FBF117;
}

.textgreen {
  color: #50fe00;
}

.textblue {
  color: #001F3F;
}

.borderone {
  border: 1px solid #911212;
}

.bordertwo {
  border: 1px dashed #911212;
}



.react-select__control {
  height: 35px !important;
  min-height: 35px !important;
  border: 1px solid #9ca3af !important;
}


/* Theme Css End */


/* loading animation */
.sk-fading-circle {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: '';
  display: block;
  margin: 5 auto;
  width: 20%;
  height: 20%;
  background-color: #992941;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}

.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {

  0%,
  39%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}

@keyframes sk-circleFadeDelay {

  0%,
  39%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}






/* pagination start */

.pagination {
  @apply items-center flex
}


.pagination>li>a,
.pagination>li>span {
  @apply relative inline-flex items-center px-3 py-1 text-base font-medium text-gray-700 cursor-pointer bg-black bg-opacity-10
}

.pagination>li:nth-child(2n + 1)>a,
.pagination>li:nth-child(2n + 1)>span {
  @apply bg-opacity-5
}

.pagination>li.active>a {
  @apply bg-[#911212] text-white
}

/* Style the active class (and buttons on mouse-over) */
.pagination>li>a:hover {
  @apply bg-red-500 text-white
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
  @apply rounded-l block
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
  @apply rounded-r block mr-0
}

/* pagination End */


/* 
.toggle-checkbox:checked {
  @apply: right-0 border-green-400;
  right: 0;
  border-color: #68D391;
}

.toggle-checkbox:checked+.toggle-label {
  @apply: bg-green-400;
  background-color: #68D391;
} */




.login_font {
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;

}



.menu {
  position: relative;
  background: #0275d8;
  font-family: 'Roboto', sans-serif;
  width: 3em;
  height: 3em;
  border-radius: 5em;
  margin: auto;
  margin-top: 2%;
  cursor: pointer;
  border: 1em solid #5795D8;
}

.menu:after {
  content: "+";
  position: absolute;
  font-weight: bold;
  color: white;
  text-align: center;
  font-size: 38px;
  margin: -19px 0 0 -2px;
}

.menu ul {
  list-style: none;
  padding: 0;
}

.menu li {
  width: 5em;
  height: 1.4em;
  padding: 0.2em;
  margin-top: 0.2em;
  text-align: center;
  border-top-right-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  transition: all 1s;
  background: #5795D8;
  opacity: 0;
  z-index: -1;
  font-weight: normal;
}

.menu:hover li {
  opacity: 1;
}

.menu:hover ul::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  display: block;
  left: 50%;
  top: "10px";
  border-width: 6.5em;
  border-radius: 0 7.5em 7.5em 0;
  border-left: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.01);
  z-index: -1;
  cursor: default;
}

.menu span {
  color: white;
  text-decoration: none;
  line-height: 1.5em;
}

.menu ul {
  transform: rotate(180deg) translateY(-2em);
  transition: 1s all;
}

.menu:hover ul {
  transform: rotate(0deg) translateY(-1em);
}

.menu li:hover {
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
  z-index: 10;
  transition: all 1s;
}

.menu li:nth-of-type(1) {
  transform: rotate(-90deg);
  position: absolute;
  left: -1.2em;
  top: -4.2em;
}

.menu li:nth-of-type(2) {
  transform: rotate(-45deg);
  position: absolute;
  left: 2em;
  top: -3em;
}

.menu li:nth-of-type(3) {
  position: absolute;
  left: 3.4em;
  top: 0.3em;
}

.menu li:nth-of-type(4) {
  transform: rotate(45deg);
  position: absolute;
  left: 2em;
  top: 3.7em;
}

.dashboard-card {
  background: linear-gradient(210deg, #aa7272 0%, rgba(99, 9, 9, 0.938) 100%);
}

.all-btn {
  background: linear-gradient(210deg, #582121 0%, rgba(223, 30, 30, 0.938) 100%);
  color: white;
  font-weight: 500;
  border-radius: 8px;
  padding: 8px 16px;
  transition: all 0.3s ease;
}

.all-btn:hover {
  background: linear-gradient(210deg, #a32c2c 0%, #f03b3b 100%);
  box-shadow: 0px 4px 10px rgba(240, 59, 59, 0.5);
}

.all-btn2 {
  background: linear-gradient(210deg, #b5890b 0%, #ffc107 100%);
  color: white;
  font-weight: 500;
  border-radius: 8px;
  padding: 8px 16px;
  transition: all 0.3s ease;
}

.all-btn2:hover {
  background: linear-gradient(210deg, #e6b80093 0%, #bb9911 100%);
  box-shadow: 0px 4px 10px rgba(116, 103, 54, 0.5);
}


.Pokercardbar::-webkit-scrollbar {
  width: 4px;
}

.Pokercardbar::-webkit-scrollbar-track {
  background-color: #ebebeb8c;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.Pokercardbar::-webkit-scrollbar-thumb {
  background-image: linear-gradient(#c43e3985, #c21b1bb4);
}